/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';
import { fetchMutation } from 'Util/Request';

import {
    MY_AFFILIATE,
    URL_MAP,
    URL_PREFIX
} from '../config';
import MyAffiliateQuery from '../query/MyAffiliate.query';

export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));

function getSelectedTabFromSectionUrl(props) {
    const {
        match: {
            url = ''
        } = {}
    } = props;
    const trimmedUrl = url.replace(/^\/[a-z]+\//, '/');

    return URL_MAP[trimmedUrl] || MY_AFFILIATE;
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: <Route
          path={ withStoreRegex('/customer/affiliate/:sectionUrl?') }
          render={ (props) => <MyAccount { ...props } selectedTab={ getSelectedTabFromSectionUrl(props) } /> }
        />,
        position: 83,
        name: MY_AFFILIATE
    }
];

const componentDidMount = (args, callback) => {
    callback(...args);

    const codeParam = location.search.match(new RegExp(`[?&]${ URL_PREFIX }=([a-zA-Z0-9]+)`));
    const savedHash = window.location.hash;
    const codeFromHashMatches = savedHash.match(new RegExp(`#${ URL_PREFIX }([a-zA-Z0-9]+)`));
    const code = (codeParam && codeParam[1]) || (codeFromHashMatches && codeFromHashMatches[1]);

    if (code) {
        const keyParam = location.search.match(/[?&]key=([a-zA-Z0-9]+)/);
        const sourceParam = location.search.match(/[?&]source=(banner|coupon)/);
        const key = keyParam && keyParam[1];
        const source = sourceParam && sourceParam[1];

        fetchMutation(MyAffiliateQuery.mutationProcessParams(code, key, source))
            .then(({ affiliateProcessParams: redirectUrl }) => {
                // if we were referred by hash - there is no need to make a redirect.
                // just remove the hash, but only if it was not changed by our own code somewhere else
                if (codeFromHashMatches && codeFromHashMatches[1]) {
                    if (savedHash == window.location.hash) {
                        window.location.hash = '';
                    }
                } else if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    const url = new URL(window.location.href);
                    url.search = '';
                    window.location.href = url.toString();
                }
            });
    }
};

// Needs to be changed with URL rewrites
export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidMount
        }
    }
};
