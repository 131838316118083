/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { PAYMENT_IGNORE_CLICK_OUTSIDE } from 'Component/CreditCardList/CreditCardList.config';

import './CreditCardForm.style';

/** @namespace Spinola/BaseTheme/Component/CreditCardForm/Component */
export class CreditCardFormComponent extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        handleMaskedInput: PropTypes.func.isRequired,
        expiry: PropTypes.string.isRequired,
        cardNumber: PropTypes.string.isRequired,
        isFirstCard: PropTypes.bool.isRequired
    };

    render() {
        const {
            handleSubmit,
            expiry,
            cardNumber,
            handleMaskedInput
        } = this.props;

        return (
            <div block="CreditCardForm">
                <div className="payment_form" id="ptez_cc_form">
                    <label className="card_number label" htmlFor="number">{ __('Card Number') }</label>
                    <i className="card_icon" />
                    <input
                      className="card_number field with-icon"
                      name="number"
                      type="text"
                      placeholder="1234 1234 1234 1234"
                      value={ cardNumber }
                      onChange={ handleMaskedInput }
                      required
                    />
                    <label className="expiry label" htmlFor="expiry">{ __('Expiry') }</label>
                    <i className="calendar_icon" />
                    <input
                      className="expiry field with-icon"
                      name="expiry"
                      type="text"
                      placeholder={ __('MM/YY') }
                      value={ expiry }
                      onChange={ handleMaskedInput }
                      required
                    />
                    <label className="name label" htmlFor="holder_name">{ __('Name on Card') }</label>
                    <input
                      className="name field"
                      name="holder_name"
                      type="text"
                      placeholder={ `${ __('e.g.') } Jane Doe` }
                      required
                    />
                    <label className="cvc label" htmlFor="cvc">{ __('CVV') }</label>
                    <i className="lock_icon" />
                    <input
                      className="cvc field with-icon"
                      name="cvc"
                      type="password"
                      placeholder={ __('CVV') }
                      required
                    />
                </div>
                <button block="CreditCardForm" elem="SaveBtn Btn" onClick={ handleSubmit } { ...PAYMENT_IGNORE_CLICK_OUTSIDE }>
                    { __('Save New Card') }
                </button>
            </div>
        );
    }
}
