const renderTabListItem = (args, callback, instance) => {
    const { props } = instance;
    const {
        affiliateEnabled
    } = props;

    if (args[0]?.[0] === 'my-affiliate' && !affiliateEnabled) {
        return null;
    }

    return (
        <>
            { callback(...args) }
        </>
    );
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        affiliateEnabled: state.ConfigReducer.affiliate_enabled
    };
};

const containerProps = (args, callback, instance) => {
    const { props } = instance;
    const {
        affiliateEnabled
    } = props;

    return {
        ...callback(args),
        affiliateEnabled
    };
};

export default {
    'Spinola/BaseTheme/Component/MyAccountTabList/Container': {
        'member-function': {
            containerProps
        }
    },
    'Spinola/BaseTheme/Component/MyAccountTabList/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Spinola/BaseTheme/Component/MyAccountTabList/Component': {
        'member-function': {
            renderTabListItem
        }
    }
};
