import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';

/** @namespace Scandiweb/SmsCustomerAccount/Shared/LoginRegister/mapStateToProps */
export const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        isOverlayVisible: state.OverlayReducer.activeOverlay === CUSTOMER_ACCOUNT,
        isMobile: state.ConfigReducer.device.isMobile
    };
};

/** @namespace Scandiweb/SmsCustomerAccount/Shared/LoginRegister/containerPropTypes */
export const containerPropTypes = (originalMember) => ({
    ...originalMember,
    isOverlayVisible: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired
});

/** @namespace Scandiweb/SmsCustomerAccount/Shared/LoginRegister/containerProps */
export const containerProps = (args, callback, instance) => {
    const { state, props } = instance;
    const { showSmsCodeForm } = state;
    const { isOverlayVisible, isMobile } = props;

    return {
        ...callback(args),
        showSmsCodeForm,
        isOverlayVisible,
        isMobile
    };
};

/** @namespace Scandiweb/SmsCustomerAccount/Shared/LoginRegister */
export class ReturnToPhoneInput {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        returnToInitialForm: this.returnToInitialForm.bind(instance)
    });

    returnToInitialForm() {
        this.setState({ showSmsCodeForm: false });
    }
}

/** @namespace Scandiweb/SmsCustomerAccount/Shared/LoginRegister/componentPropTypes */
export const componentPropTypes = (originalMember) => ({
    ...originalMember,
    showSmsCodeForm: PropTypes.bool.isRequired,
    returnToInitialForm: PropTypes.func.isRequired,
    isOverlayVisible: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired
});

/** @namespace Scandiweb/SmsCustomerAccount/Shared/LoginRegister/smsFormField */
export const smsFormField = () => (
    <Field
      type={ FIELD_TYPE.text }
      attr={ {
          id: 'sms_code',
          name: 'sms_code',
          placeholder: __('Code')
      } }
      validateOn={ ['onChange'] }
      validationRule={ {
          isRequired: true
      } }
      addRequiredTag
    />
);
