import { Field } from 'Util/Query';

/** @namespace Spinola/BaseTheme/Query/Paymentez/Query */
export class PaymentezQuery {
    getListCards() {
        return new Field('cardList')
            .addFieldList([this._getCardsField(), 'result_size']);
    }

    deleteCard(cardToken) {
        return new Field('deleteCard')
            .addArgument('cardToken', 'String!', cardToken);
    }

    getConfig() {
        return new Field('paymentezConfig')
            .addFieldList(this._getConfigFields());
    }

    _getCardsField() {
        return new Field('cards')
            .addFieldList([
                'bin',
                'status',
                'token',
                'holder_name',
                'expiry_year',
                'expiry_month',
                'transaction_reference',
                'type',
                'number'
            ]);
    }

    _getConfigFields() {
        return [
            'is_active',
            'title',
            'environment',
            'brands',
            'allow_installments',
            'installments_types',
            this._getCredentialsField()
        ];
    }

    _getCredentialsField() {
        return new Field('credentials')
            .addFieldList([
                'application_code',
                'application_key'
            ]);
    }
}

export default new PaymentezQuery();
