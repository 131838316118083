import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

/** @namespace Spinola/BaseTheme/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    containerProps = () => {
        const {
            isCloseOnOutsideClick,
            activeOverlay,
            areOtherOverlaysOpen,
            changeHeaderState,
            children,
            id,
            isMobile,
            isStatic,
            mix,
            contentMix,
            onClose,
            onHide,
            onVisible,
            shouldPopupClose,
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousNavigationState,
            identifier
        } = this.props;

        return {
            isCloseOnOutsideClick,
            activeOverlay,
            areOtherOverlaysOpen,
            changeHeaderState,
            children,
            id,
            isMobile,
            isStatic,
            mix,
            contentMix,
            shouldPopupClose,
            onClose,
            onHide,
            onVisible,
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousNavigationState,
            title: this._getPopupTitle(),
            identifier
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
