/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ReactComponent as ChevronLeft } from '../../style/icons/chevron_left.svg';
import solidarioIcon from '../../style/icons/credit-card/ic_alia.svg';
import alkostoIcon from '../../style/icons/credit-card/ic_alkosto.png';
import amexIcon from '../../style/icons/credit-card/ic_amex.svg';
import auraIcon from '../../style/icons/credit-card/ic_aura.svg';
import bbvaIcon from '../../style/icons/credit-card/ic_bbva.svg';
import carnetIcon from '../../style/icons/credit-card/ic_carnet.svg';
import codensaIcon from '../../style/icons/credit-card/ic_codensa.png';
import csIcon from '../../style/icons/credit-card/ic_credisensa.png';
import csdIcon from '../../style/icons/credit-card/ic_csd.png';
import dinersIcon from '../../style/icons/credit-card/ic_diners.svg';
import discoverIcon from '../../style/icons/credit-card/ic_discover.svg';
import eloIcon from '../../style/icons/credit-card/ic_elo.png';
import epmIcon from '../../style/icons/credit-card/ic_epm.png';
import exitoIcon from '../../style/icons/credit-card/ic_exito.png';
import hpcIcon from '../../style/icons/credit-card/ic_hipercard.svg';
import jcIcon from '../../style/icons/credit-card/ic_jcb.svg';
import maestroIcon from '../../style/icons/credit-card/ic_maestro.svg';
import masterCardIcon from '../../style/icons/credit-card/ic_mastercard.svg';
import sodexoIcon from '../../style/icons/credit-card/ic_sodexo.svg';
import olimpicaIcon from '../../style/icons/credit-card/ic_tcolimpica.png';
import upIcon from '../../style/icons/credit-card/ic_unionpay.png';
import visaIcon from '../../style/icons/credit-card/ic_visa.svg';
import { ReactComponent as TrashIcon } from '../../style/icons/trashcan.svg';
import CreditCardForm from '../CreditCardForm';
import { CREDIT_CARD_DEFAULT_MASK, PAYMENT_IGNORE_CLICK_OUTSIDE } from './CreditCardList.config';

import './CreditCardList.style';

/** @namespace Spinola/BaseTheme/Component/CreditCardList/Component */
export class CreditCardListComponent extends PureComponent {
    static propTypes = {
        mode: PropTypes.number.isRequired,
        selectedCard: PropTypes.string.isRequired,
        onModeChange: PropTypes.func.isRequired,
        onSelectCard: PropTypes.func.isRequired,
        deleteCard: PropTypes.func.isRequired,
        cardList: PropTypes.arrayOf(
            PropTypes.shape({
                bin: PropTypes.string,
                status: PropTypes.string,
                token: PropTypes.string,
                holder_name: PropTypes.string,
                expiry_year: PropTypes.string,
                expiry_month: PropTypes.string,
                transaction_reference: PropTypes.string,
                type: PropTypes.string,
                number: PropTypes.string
            })
        ).isRequired
    };

    iconMap = (key) => {
        switch (key) {
        case 'vi':
            return <img block="CreditCardList" elem="CardLogo" src={ visaIcon } alt="Exito" />;
        case 'mc':
            return <img block="CreditCardList" elem="CardLogo" src={ masterCardIcon } alt="Exito" />;
        case 'ax':
            return <img block="CreditCardList" elem="CardLogo" src={ amexIcon } alt="Exito" />;
        case 'di':
            return <img block="CreditCardList" elem="CardLogo" src={ dinersIcon } alt="Exito" />;
        case 'dc':
            return <img block="CreditCardList" elem="CardLogo" src={ discoverIcon } alt="Exito" />;
        case 'ms':
            return <img block="CreditCardList" elem="CardLogo" src={ maestroIcon } alt="Exito" />;
        case 'ex':
            return <img block="CreditCardList" elem="CardLogo" src={ exitoIcon } alt="Exito" />;
        case 'ak':
            return <img block="CreditCardList" elem="CardLogo" src={ alkostoIcon } alt="Alkosto" />;
        case 'cd':
            return <img block="CreditCardList" elem="CardLogo" src={ codensaIcon } alt="Codensa" />;
        case 'sx':
            return <img block="CreditCardList" elem="CardLogo" src={ sodexoIcon } alt="Sodexo" />;
        case 'ol':
            return <img block="CreditCardList" elem="CardLogo" src={ olimpicaIcon } alt="Olimpica" />;
        case 'ep':
            return <img block="CreditCardList" elem="CardLogo" src={ epmIcon } alt="Epm" />;
        case 'csd':
            return <img block="CreditCardList" elem="CardLogo" src={ csdIcon } alt="CSD" />;
        case 'bbva':
            return <img block="CreditCardList" elem="CardLogo" src={ bbvaIcon } alt="CSD" />;
        case 'cn':
            return <img block="CreditCardList" elem="CardLogo" src={ carnetIcon } alt="CSD" />;
        case 'cs':
            return <img block="CreditCardList" elem="CardLogo" src={ csIcon } alt="CS" />;
        case 'so':
            return <img block="CreditCardList" elem="CardLogo" src={ solidarioIcon } alt="CS" />;
        case 'up':
            return <img block="CreditCardList" elem="CardLogo" src={ upIcon } alt="UP" />;
        case 'el':
            return <img block="CreditCardList" elem="CardLogo" src={ eloIcon } alt="ELO" />;
        case 'jc':
            return <img block="CreditCardList" elem="CardLogo" src={ jcIcon } alt="ELO" />;
        case 'au':
            return <img block="CreditCardList" elem="CardLogo" src={ auraIcon } alt="ELO" />;
        case 'hpc':
            return <img block="CreditCardList" elem="CardLogo" src={ hpcIcon } alt="ELO" />;
        default:
            return null;
        }
    };

    renderCard = (card, hasDelete = false) => {
        const {
            number,
            type,
            expiry_month,
            expiry_year,
            token
        } = card;
        const {
            onSelectCard, onModeChange, deleteCard, mode
        } = this.props;
        const cardMask = CREDIT_CARD_DEFAULT_MASK.substring(0, 15);
        const monthMask = '00';

        return (
            <button
              onClick={ (e) => {
                  e.preventDefault();
                  if (mode === 1) {
                      onSelectCard(token);
                      onModeChange(0);
                  }
              } }
              block="CreditCardList"
              elem="Card"
              key={ token }
              { ...PAYMENT_IGNORE_CLICK_OUTSIDE }
            >
                <div block="CreditCardList" elem="CardInfo">
                    <p block="CreditCardList" elem="CardNumber">
                        <span block="CreditCardList" elem="CardIcon">{ this.iconMap(type) }</span>
                        { cardMask + number }
                    </p>
                    <p block="CreditCardList" elem="CardExpiry">
                        { `${monthMask.substring(0, 2 - expiry_month.length) + expiry_month
                        }/${expiry_year.substring(2, 4)
                        }` }
                    </p>
                </div>
                { hasDelete && (
                    <button
                      onClick={ () => deleteCard(token) }
                      block="CreditCardList"
                      elem="DeleteBtn"
                      { ...PAYMENT_IGNORE_CLICK_OUTSIDE }
                    >
                        <TrashIcon />
                    </button>
                ) }
            </button>
        );
    };

    renderSelectedCard = () => {
        const { onModeChange, cardList, selectedCard: selectedToken } = this.props;
        const selectedCard = cardList.filter(({ token }) => token === selectedToken)[0];

        return (
            <div block="CreditCardList" elem="SelectedCard">
                { this.renderCard(selectedCard) }
                <button
                  block="CreditCardList"
                  elem="ChangeButton"
                  onClick={ () => onModeChange(1) }
                  { ...PAYMENT_IGNORE_CLICK_OUTSIDE }
                >
                    { __('change') }
                </button>
            </div>
        );
    };

    renderCardList = () => {
        const { cardList, onModeChange } = this.props;

        return (
            <>
                { cardList.filter(({ status }) => status === 'valid').map((card) => this.renderCard(card, true)) }
                <button
                  block="CreditCardList"
                  elem="AddCard"
                  onClick={ () => onModeChange(2) }
                  { ...PAYMENT_IGNORE_CLICK_OUTSIDE }
                >
                    { __('Add a new card') }
                </button>
            </>
        );
    };

    renderNewCardForm = () => {
        const { onModeChange, onSelectCard, cardList } = this.props;

        return (
            <CreditCardForm
              onModeChange={ onModeChange }
              onSelectCard={ onSelectCard }
              isFirstCard={ cardList.length === 0 }
            />
        );
    };

    renderContent = () => {
        const { mode, cardList } = this.props;

        if (mode === 1) {
            return this.renderCardList();
        }
        if (mode === 2 || cardList.length === 0) {
            return this.renderNewCardForm();
        }

        return this.renderSelectedCard();
    };

    renderHeaderMessage = () => {
        const { mode, cardList } = this.props;

        if (cardList.length === 0) {
            return __('Add a new card');
        }

        switch (mode) {
        case 1:
            return __('Choose card');
        case 2:
            return __('Add a new card');
        default:
            return __('Selected card');
        }
    };

    render() {
        const { selectedCard, mode, onModeChange } = this.props;

        return (
            <div block="CreditCardList">
                <h3 block="CreditCardList" elem="Header">
                    { !!selectedCard && mode !== 0 && (
                        <button
                          block="CreditCardList"
                          elem="BackBtn"
                          onClick={ (e) => {
                              e.preventDefault();
                              onModeChange(mode - 1);
                          } }
                          { ...PAYMENT_IGNORE_CLICK_OUTSIDE }
                        >
                            <span className="back_icon">
                                <ChevronLeft />
                            </span>
                        </button>
                    ) }
                    { this.renderHeaderMessage() }
                </h3>
                <input type="hidden" id="ptez_cc_token" value={ selectedCard } />
                { this.renderContent() }
            </div>
        );
    }
}

export default CreditCardListComponent;
