/* eslint-disable no-undef */
import { PureComponent } from 'react';

import { fetchMutation, fetchQuery } from 'Util/Request';

import PaymentezQuery from '../../query/Paymentez.query.js';
import { loadExternalDep } from '../../util/Scripts/loadExternalDep';
import CreditCardListComponent from './CreditCardList.component';

/** @namespace Spinola/BaseTheme/Component/CreditCardList/Container */
export class CreditCardListContainer extends PureComponent {
    config = {};

    state = {
        mode: 0, // 0 = Selected card; 1 = Change selection; 2 = Add new card;
        cardList: [],
        selectedCard: '',
        isLoading: true
    };

    containerFunctions = {
        onModeChange: this.onModeChange.bind(this),
        onSelectCard: this.onSelectCard.bind(this),
        deleteCard: this.deleteCard.bind(this)
    };

    async componentDidMount() {
        await this.fetchConfig();
        this.fetchCardList();

        await loadExternalDep('https://code.jquery.com/jquery-3.7.1.min.js');
        await loadExternalDep('https://cdn.paymentez.com/ccapi/sdk/payment_stable.min.js');
        this.generateTokenize();
    }

    async fetchConfig() {
        const configQuery = PaymentezQuery.getConfig();
        const { paymentezConfig } = await fetchQuery(configQuery);

        this.config = paymentezConfig;
    }

    async fetchCardList() {
        this.setState({ isLoading: true });
        const cardListQuery = PaymentezQuery.getListCards();

        await fetchQuery(cardListQuery).then(
            /** @namespace Spinola/BaseTheme/Component/CreditCardList/Container/CreditCardListContainer/fetchCardList/fetchQuery/then */
            ({ cardList }) => {
                this.setState({ cardList: cardList.cards });
                if (cardList.result_size > 0) {
                    this.setState({ selectedCard: cardList.cards[0].token });
                }
                this.setState({ isLoading: false });
            }
        );
    }

    onModeChange(mode) {
        this.setState({ mode });
    }

    onSelectCard(token) {
        const { cardList } = this.state;
        if (!cardList.filter((card) => card.token === token).length) {
            this.fetchCardList().then(
                /** @namespace Spinola/BaseTheme/Component/CreditCardList/Container/CreditCardListContainer/onSelectCard/fetchCardList/then */
                () => {
                    this.setState({ selectedCard: token });
                }
            );

            return;
        }

        this.setState({ selectedCard: token });
    }

    containerProps = () => {
        const { mode, cardList, selectedCard } = this.state;
        const { config } = this;

        return {
            mode,
            cardList,
            selectedCard,
            config
        };
    };

    async deleteCard(token) {
        const deleteCardQuery = PaymentezQuery.deleteCard(token);

        await fetchMutation(deleteCardQuery);
        this.setState({ isLoading: true });
        this.fetchCardList();
    }

    generateTokenize = () => {
        const { config: { environment: e, credentials: c } } = this;

        if (Payment) {
            Payment.init(e, c.application_code, c.application_key);
        }
    };

    render() {
        const { isLoading } = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        return <CreditCardListComponent { ...this.containerFunctions } { ...this.containerProps() } />;
    }
}

export default CreditCardListContainer;
