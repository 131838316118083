import {
    VALIDATION_INPUT_TYPE as sourceInputType,
    VALIDATION_MESSAGES as sourceValidationMessages,
    VALIDATION_RULES as sourceValidationRules
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_INPUT_TYPE = {
    ...sourceInputType,
    CPF: 'CPF',
    CEDULA: 'Cedula'
};

export const VALIDATION_MESSAGES = {
    ...sourceValidationMessages,
    [VALIDATION_INPUT_TYPE.CPF]: __('Enter a valid CPF'),
    [VALIDATION_INPUT_TYPE.CEDULA]: __('Enter a valid Cedula (only 10 digits are allowed)')
};

export const VALIDATION_RULES = {
    ...sourceValidationRules,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,7}$/im,
    [VALIDATION_INPUT_TYPE.CPF]: /(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{3}\d{3}\d{3}\d{2})/,
    [VALIDATION_INPUT_TYPE.CEDULA]: /^\d{10}$/
};
