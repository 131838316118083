export const componentWillUnmount = () => {
    // do nothing
};

export default {
    'Spinola/BaseTheme/Component/CurrencySwitcher/Container': {
        'member-function': {
            componentWillUnmount
        }
    }
};
