import ClickOutside from 'Component/ClickOutside';
import SourcePopup from 'SourceComponent/Popup/Popup.component';

/** @namespace Spinola/BaseTheme/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    /**
     * override to remove history.goBack()
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hidePopupAndGoBack() {
        this.hidePopUp();
    }

    renderContent() {
        const { children, contentMix, identifier } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside } identifier={ identifier }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
