/* eslint-disable consistent-return */
import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { getCartId } from 'Util/Cart';
import {
    fetchMutation
} from 'Util/Request';

const PAYMENTEZ_CARD_PAYMENT_CODE = 'paymentez_card';

export const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [{ paymentMethod: { code, additional_data, purchase_order_number } }] = args;

    if (code !== PAYMENTEZ_CARD_PAYMENT_CODE) {
        return callback(args);
    }

    const { logoutSessionExpired } = instance.props;
    const isCustomerSignedIn = isSignedIn();
    const guest_cart_id = getCartId();

    if (!isCustomerSignedIn && !guest_cart_id) {
        logoutSessionExpired();
        return;
    }

    try {
        const token = document.querySelector('#ptez_cc_token').value;
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            cart_id: guest_cart_id,
            payment_method: {
                code,
                [code]: { ...additional_data, token },
                purchase_order_number
            }
        }));

        await instance.placeOrder(guest_cart_id);
    } catch (e) {
        instance._handleError(e);
    }
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder
        }
    }
};
