import { DEFAULT_ERROR_MESSAGE } from 'SourceUtil/Request/Error';

export * from 'SourceUtil/Request/Error';

export const NO_TRANSLATE_MESSAGES = {
    'Internal server error': __('Internal server error')
};

/** @namespace Spinola/BaseTheme/Util/Request/Error/getErrorMessage */
export const getErrorMessage = (error, defaultMessage = DEFAULT_ERROR_MESSAGE) => {
    const {
        message = defaultMessage
    } = error?.length ? error[0] : error || {};

    // Internal server error message is not translated anywhere, so we replace it with a phrase
    return NO_TRANSLATE_MESSAGES[message] || message;
};
