/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';

import {
    THIRD_SECTION
} from 'Type/Account.type';

import {
    MY_AFFILIATE,
    MY_AFFILIATE_BANNERS,
    MY_AFFILIATE_BANNERS_URL,
    MY_AFFILIATE_HOME,
    MY_AFFILIATE_HOME_URL,
    MY_AFFILIATE_MY_CREDIT,
    MY_AFFILIATE_MY_CREDIT_URL,
    MY_AFFILIATE_REFER_FRIEND,
    MY_AFFILIATE_REFER_FRIEND_URL,
    MY_AFFILIATE_REFER_HISTORY,
    MY_AFFILIATE_REFER_HISTORY_URL,
    MY_AFFILIATE_SETTINGS,
    MY_AFFILIATE_SETTINGS_URL,
    MY_AFFILIATE_SIGNUP,
    MY_AFFILIATE_SIGNUP_URL
} from '../config';

export const MyAffiliateHome = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-home" */
    '../component/MyAffiliateHome'
));

export const MyAffiliateSignup = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-signup" */
    '../component/MyAffiliateSignup'
));

export const MyAffiliateMyCredit = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-my-credit" */
    '../component/MyAffiliateMyCredit'
));

export const MyAffiliateReferFriend = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-refer-friend" */
    '../component/MyAffiliateReferFriend'
));

export const MyAffiliateSettings = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-settings" */
    '../component/MyAffiliateSettings'
));

export const MyAffiliateReferHistory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-refer-history" */
    '../component/MyAffiliateReferHistory'
));

export const MyAffiliateBanners = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-affiliate-banners" */
    '../component/MyAffiliateBanners'
));

const tabMap = (originalMember) => {
    const subTabs = [
        MY_AFFILIATE_HOME,
        MY_AFFILIATE_SIGNUP,
        MY_AFFILIATE_MY_CREDIT,
        MY_AFFILIATE_REFER_FRIEND,
        MY_AFFILIATE_BANNERS,
        MY_AFFILIATE_SETTINGS,
        MY_AFFILIATE_REFER_HISTORY
    ];

    const newTabs = {
        [MY_AFFILIATE]: {
            url: '',
            tabName: __('My Affiliate'),
            section: THIRD_SECTION,
            isFullUrl: true,
            subTabs
        },
        [MY_AFFILIATE_HOME]: {
            url: MY_AFFILIATE_HOME_URL,
            tabName: __('Home'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true,
            isHideTitle: true
        },
        [MY_AFFILIATE_SIGNUP]: {
            url: MY_AFFILIATE_SIGNUP_URL,
            tabName: __('Signup'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true
        },
        [MY_AFFILIATE_MY_CREDIT]: {
            url: MY_AFFILIATE_MY_CREDIT_URL,
            tabName: __('My Credit'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true
        },
        [MY_AFFILIATE_REFER_FRIEND]: {
            url: MY_AFFILIATE_REFER_FRIEND_URL,
            tabName: __('Refer Now'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true
        },
        [MY_AFFILIATE_SETTINGS]: {
            url: MY_AFFILIATE_SETTINGS_URL,
            tabName: __('Setting'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true
        },
        [MY_AFFILIATE_REFER_HISTORY]: {
            url: MY_AFFILIATE_REFER_HISTORY_URL,
            tabName: __('Refer History'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true
        },
        [MY_AFFILIATE_BANNERS]: {
            url: MY_AFFILIATE_BANNERS_URL,
            tabName: __('Banners'),
            section: THIRD_SECTION,
            isFullUrl: true,
            isSubTab: true
        }
    };

    return { ...originalMember, ...newTabs };
};

const addAffiliateToRenderMap = (originalMember) => {
    const affiliateMap = {
        [MY_AFFILIATE]: MyAffiliateHome,
        [MY_AFFILIATE_HOME]: MyAffiliateHome,
        [MY_AFFILIATE_SIGNUP]: MyAffiliateSignup,
        [MY_AFFILIATE_MY_CREDIT]: MyAffiliateMyCredit,
        [MY_AFFILIATE_REFER_FRIEND]: MyAffiliateReferFriend,
        [MY_AFFILIATE_SETTINGS]: MyAffiliateSettings,
        [MY_AFFILIATE_REFER_HISTORY]: MyAffiliateReferHistory,
        [MY_AFFILIATE_BANNERS]: MyAffiliateBanners
    };

    return { ...originalMember, ...affiliateMap };
};

export default {
    'Spinola/BaseTheme/Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap: addAffiliateToRenderMap
        }
    }
};
