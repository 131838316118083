/* eslint-disable consistent-return */
/** @namespace Spinola/BaseTheme/Util/Scripts/LoadExternalDep/loadExternalDep */
/**
 * Append external dependency to the body with optional callback.
 * @param {string} src - Source url for the script to be loaded.
 */
export function loadExternalDep(src) {
    window.util_load_external_dep_cache = window.util_load_external_dep_cache || {};

    if (src in window.util_load_external_dep_cache) {
        return window.util_load_external_dep_cache[src];
    }

    window.util_load_external_dep_cache[src] = new Promise(
        (resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;

            document.body.append(script);
        }
    );

    return window.util_load_external_dep_cache[src];
}
