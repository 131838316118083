/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-unused-vars */

import { DL_VAL_CHECKOUT_SHIPPING_STEP } from '../../data/checkout';
import { fireCheckoutEvent, fireCheckoutOptionEvent } from '../../event/checkout';

const addFireCheckoutOptionEventForShipping = (args, callback, instance) => {
    callback(...args);

    const { selectedShippingMethod: { carrier_title } } = instance.state;

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_SHIPPING_STEP,
        carrier_title
    );
};

const addFireCheckoutEventForShipping = (args, callback) => {
    callback(...args);
    fireCheckoutEvent(DL_VAL_CHECKOUT_SHIPPING_STEP);
};

// may not be applicable due to virtual products
// export default {
//     'Component/CheckoutShipping/Container': {
//         'member-function': {
//             onShippingSuccess: addFireCheckoutOptionEventForShipping,
//             componentDidMount: addFireCheckoutEventForShipping
//         }
//     }
// };
