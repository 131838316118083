/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import BrowserDatabase from 'Util/BrowserDatabase';

import { showNotification } from '../../store/Notification/Notification.action';
import { CreditCardFormComponent } from './CreditCardForm.component';
import { CREDIT_CARD_INPUT_MASK, EXPIRY_INPUT_MASK } from './CreditCardForm.config';

/** @namespace Spinola/BaseTheme/Component/CreditCardForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Spinola/BaseTheme/Component/CreditCardForm/Container */
export class CreditCardFormContainer extends PureComponent {
    state = {
        expiry: '',
        cardNumber: ''
    };

    static propTypes = {
        onModeChange: PropTypes.func.isRequired,
        onSelectCard: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        isFirstCard: PropTypes.bool.isRequired
    };

    containerProps = () => {
        const { expiry, cardNumber } = this.state;
        const { isFirstCard } = this.props;

        return {
            expiry,
            cardNumber,
            isFirstCard
        };
    };

    containerFunctions = () => {
        const {
            handleSubmit, handleCancel, handleMaskedInput
        } = this;
        const { onModeChange, onSelectCard } = this.props;

        return {
            handleSubmit,
            handleCancel,
            onModeChange,
            onSelectCard,
            handleMaskedInput
        };
    };

    handleMaskedInput = (e) => {
        const { cardNumber, expiry } = this.state;
        const { name, value } = e.target;

        if (name === 'number') {
            const newValue = this.applyMaskToInput(value, cardNumber, CREDIT_CARD_INPUT_MASK, ' ');
            this.setState({ cardNumber: newValue });
        }
        if (name === 'expiry') {
            const newValue = this.applyMaskToInput(value, expiry, EXPIRY_INPUT_MASK, '/');
            this.setState({ expiry: newValue });
        }
    };

    applyMaskToInput = (val, stateVal, mask, separator) => {
        const isSeparatorBackspace = stateVal.length - val.length === 1 && stateVal[stateVal.length - 1] === separator;
        const newVal = isSeparatorBackspace ? val.slice(0, -1) : val;
        const trimmedVal = PaymentForm.numbersOnlyString(newVal);
        const formattedVal = PaymentForm.applyFormatMask(trimmedVal, mask);

        return formattedVal;
    };

    getTokenizeData = () => {
        const { id, email } = BrowserDatabase.getItem('customer');
        const session_id = Payment.getSessionId();
        Payment.dataCollector(session_id);

        const data = {
            session_id,
            user: {
                id: id.toString(),
                email
            }
        };

        return data;
    };

    handleSuccess = (response) => {
        const { onModeChange, onSelectCard, showNotification } = this.props;

        if (response.card.status === 'valid') {
            onSelectCard(response.card.token);
            showNotification('success', __('Your card was added successfully'));
        } else {
            showNotification('error', __('Card was rejected or approval pending'));
        }

        onModeChange(0);
    };

    handleError = (error = {}) => {
        const { showNotification } = this.props;
        const { error: nestedError } = error;

        if (nestedError) {
            const message = nestedError.help || nestedError.type || __('Something went wrong');
            showNotification('error', message);

            return;
        }

        showNotification('error', error.help || __('Something went wrong'));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.addCard();
    };

    handleCancel = (e) => {
        const { onModeChange } = this.props;
        e.preventDefault();

        onModeChange(0);
    };

    getFormData = () => {
        const formDiv = document.querySelector('#ptez_cc_form');
        const cardData = { card: {} };

        formDiv.childNodes.forEach((elem) => {
            if ((!elem.name || !elem.value) || (elem && elem.nodeName !== 'INPUT' && elem.value)) {
                return;
            }

            const { name, value } = elem;

            if (name === 'expiry') {
                const [month, year] = value.split('/');
                cardData.card = {
                    ...cardData.card,
                    expiry_month: parseInt(month, 10),
                    expiry_year: parseInt(`20${year}`, 10)
                };

                return;
            }

            if (name === 'number') {
                cardData.card[name] = value.replace(/\D/g, '');
                return;
            }

            cardData.card[name] = value;
        });

        return cardData;
    };

    addCard = async () => {
        const { showNotification } = this.props;
        const { user: { id, email } } = this.getTokenizeData();
        const cardData = this.getFormData();

        const requiredFields = ['number', 'expiry_month', 'expiry_year', 'cvc', 'holder_name'];
        const isValidForm = requiredFields.every((field) => !!cardData.card[field]);

        if (!isValidForm) {
            showNotification('error', __('Required credit card form fields missing'));
            return;
        }

        await Payment.addCard(id, email, cardData, this.handleSuccess, this.handleError);
    };

    render() {
        return <CreditCardFormComponent { ...this.containerFunctions() } { ...this.containerProps() } />;
    }
}

export default withRouter(connect(null, mapDispatchToProps)(CreditCardFormContainer));
