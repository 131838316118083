/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { DETAILS_STEP } from 'Route/Checkout/Checkout.config';

import DiscountRule from '../component/DiscountRule';

const addFullDiscountData = (isCartOverlay = false) => (args, callback, instance) => {
    const {
        checkoutStep,
        totals: {
            prices: {
                amasty_discounts,
                applied_rule_ids,
                coupon_code,
                discount,
                quote_currency_code
            } = {}
        }
    } = instance.props;

    if (!applied_rule_ids) {
        return null;
    }

    const { amount: { value: discount_amount = 0 } = {} } = discount || {};
    const label = coupon_code ? __('Coupon') : __('Discount');
    const discountAmount = -Math.abs(discount_amount);

    const renderForCartOverlay = (children) => (
        <dl
          block="CartOverlay"
          elem="Discount"
        >
            { children }
        </dl>
    );

    const freeTicketDiscount = amasty_discounts.find(({ name }) => name.includes('Free lottery ticket'));

    const renderDiscountRule = () => (freeTicketDiscount
        ? (
            <>
                <DiscountRule
                  price={ freeTicketDiscount?.discount_amount }
                  title={ __('Free ticket') }
                  coupon_code={ coupon_code }
                  currency={ quote_currency_code }
                  amastyDiscounts={ amasty_discounts }
                  isSuccessPage={ checkoutStep === DETAILS_STEP }
                />
                { amasty_discounts.length > 1 && (
                    <DiscountRule
                      price={ discountAmount - freeTicketDiscount?.discount_amount }
                      title={ label }
                      coupon_code={ coupon_code }
                      currency={ quote_currency_code }
                      amastyDiscounts={ amasty_discounts }
                      isSuccessPage={ checkoutStep === DETAILS_STEP }
                    />
                ) }
            </>
        )
        : (
            <DiscountRule
              price={ discountAmount }
              title={ label }
              coupon_code={ coupon_code }
              currency={ quote_currency_code }
              amastyDiscounts={ amasty_discounts }
              isSuccessPage={ checkoutStep === DETAILS_STEP }
            />
        ));

    return (
        isCartOverlay ? renderForCartOverlay(renderDiscountRule()) : renderDiscountRule()
    );
};

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderDiscount: addFullDiscountData()
        }
    },
    'Component/CartOverlay/Component': {
        'member-function': {
            renderDiscount: addFullDiscountData(true)
        }
    }
};
