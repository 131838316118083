/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireViewCartEvent } from '../../event/cart';

// eslint-disable-next-line no-unused-vars
const afterMinicartButtonClick = (args, callback, instance) => {
    callback(...args);

    const {
        activeOverlay,
        cartTotals: {
            items,
            prices: {
                quote_currency_code,
                subtotal_including_tax: {
                    value
                } = {}
            } = {}
        } = {}
    } = instance.props;

    if (!activeOverlay) {
        fireViewCartEvent(items, value, quote_currency_code, null);
    }
};

// see Header.plugin.js in the ../spinola directory
// export default {
//     'Component/Header/Container': {
//         'member-function': {
//             onMinicartButtonClick: afterMinicartButtonClick
//         }
//     }
// };
