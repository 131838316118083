/**
 * Mageplaza Affiliate compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAffiliateDiscountCodeComponent from '../component/MyAffiliateDiscountCode';

const addAffiliateCouponCode = (args, callback, instance) => {
    const { props } = instance;
    const {
        affiliateEnabled
    } = props;

    if (!affiliateEnabled) {
        return (
            <>
                { callback(...args) }
            </>
        );
    }

    return (
        <>
            { callback(...args) }
            <MyAffiliateDiscountCodeComponent />
        </>
    );
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        affiliateEnabled: state.ConfigReducer.affiliate_enabled
    };
};

const containerProps = (args, callback, instance) => {
    const { props } = instance;
    const {
        affiliateEnabled
    } = props;

    return {
        ...callback(args),
        affiliateEnabled
    };
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDiscountCode: addAffiliateCouponCode
        }
    }
};
