/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-unused-vars */

import { SHOW_NOTIFICATION } from 'Store/Notification/Notification.action';

import { fireErrorMessageEvent } from '../../event/errorMessage';

/**
 * TEMPORARILY DISABLED
 */
const addFireErrorMessageEvent = (args, callback) => {
    const [, action] = args;

    if (action.type !== SHOW_NOTIFICATION) {
        return callback(...args);
    }

    const { msgType, msgText } = action;

    if (msgType === 'error') {
        fireErrorMessageEvent(msgText);
    }

    return callback(...args);
};

// export default {
//     'Store/Notification/Reducer/NotificationReducer': {
//         function: addFireErrorMessageEvent
//     }
// };
