import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';

export * from 'SourceQuery/Checkout.query';

/** @namespace Spinola/BaseTheme/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    _getOrderField() {
        return new Field('order')
            .addFieldList([
                'order_id',
                'shipping_amount',
                'used_wallet_amount',
                'total_paid',
                'redirect_url'
            ]);
    }
}

export default new CheckoutQuery();
